import stateExtender from '@/store/utils/stateExtender';
import RepositoryFactory from '@/services/repository-factory';
import datatableModule from '@/store/builder/datatableModule';
import table from '@/components/builders/configs/tables/usersTable';
import filters from '@/components/builders/configs/filters/userFilters';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import bulkActions from './submodules/bulkActions';

const usersRepository = RepositoryFactory.get('users');
const state = {
  repository: usersRepository,
  error: null,
  availableSellers: [],
  sellerStatuses: [
    {
      value: 'new',
      label: 'New',
      color: 'blue accent-3 white--text',
    },
    {
      value: 'in_progress',
      label: 'In progress',
      color: 'deep-purple accent-2 white--text',
    },
    {
      value: 'paid',
      label: 'Paid',
      color: 'green accent-4 white--text',
    },
    {
      value: 'cancel',
      label: 'Canceled',
      color: 'deep-orange darken-4 white--text',
    },
  ],
};

export default stateExtender({
  namespaced: true,
  state: () => state,
  modules: {
    table: datatableModule({
      fetch: usersRepository.table,
      headers: table.headers.filter((header) => header.isShown),
      allHeaders: table.headers,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 30,
      },
      search: filters,
    }),
    bulkActions,
  },
  getters,
  mutations,
  actions,
});
