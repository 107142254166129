import dateFormatter from '@/services/functions/date-filter';

export default function createUserFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    accountType: data.account_type,
    actions: {
      editable: data?.actions?.editable,
    },
    additionalInfo: data.additional_info,
    affiliateCode: data.affiliate_code,
    affiliates: data.affiliates,
    seller: data.seller,
    sellerStatus: data.seller_status,
    sellerId: data.seller_id,
    balance: data.balance,
    companyAddress: data.company_address,
    companyName: data.company_name,
    companySite: data.company_site,
    createdAt: dateFormatter(data.created_at),
    deactivatedAt: dateFormatter(data.deactivated_at),
    email: data.email,
    id: data.id,
    name: data.name,
    notificationsAboutOrderActions: data.notifications_about_order_actions,
    notificationsAboutPaymentActions: data.notifications_about_payment_actions,
    permissions: (data.permissions || []).map(
      (permission) => ({
        id: permission.id,
        name: permission.name,
        group: permission.group,
      }),
    ),
    referralProgramPercent: data.referral_program_percent !== null
      ? Math.round(data.referral_program_percent * 100) : '',
    roles: (data.roles || []).map(
      (role) => ({
        id: role.id,
        name: role.name,
      }),
    ),
    spent: data.spent,
    userServiceExists: data.user_service_exists,
    paymentMethods: data.payment_methods,
    vat: data.vat,
    frozenAt: dateFormatter(data.frozen_at),
    status: data.status,
    messengers: data.messengers,
    referralUserBalance: data.referral_user_balance,
    taskUserBalance: data.task_user_balance,
    currentGrade: data.current_grade,
    gradesEnabled: data.grades_enabled,
    gradeDiscountsEnabled: data.grade_discounts_enabled,
  };
}
