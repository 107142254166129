import {
  createDateFilter,
  createTextFilter,
  createPackagesTypeFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses,
  createCellActions,
  createCellLink,
  createCellRuns,
} from '../builder-definition/tables';
import { createCellUrl } from '../builder-definition/tables/plain-builders';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'orders.id',
      width: '90px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'comboOrder',
        statusProp: 'status',
      }),
    },
    {
      value: 'user',
      name: 'users.email',
      text: 'Users',
      width: '153px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'url',
      text: 'Url',
      width: '125px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search url' }),
      ...createCellUrl(),
    },
    {
      value: 'package.type',
      name: 'packages.type',
      text: 'Type',
      width: '200px',
      sortable: false,
      isShown: true,
      customFilter: createPackagesTypeFilter(),
    },
    {
      value: 'package.name',
      name: 'service_translations.name',
      text: 'Package name',
      width: '200px',
      customFilter: createTextFilter({ placeholder: 'Search Package' }),
      sortable: false,
      isShown: true,
    },
    {
      value: 'ordersCount',
      name: 'orders_count',
      text: 'Orders count',
      width: '72px',
      isShown: true,
      ...createCellRuns({
        currentProp: 'ordersCount',
        singlRuns: true,
        to: (item) => (item.ordersCount !== 1
          ? {
            name: 'Orders',
            query: {
              type: 'combo_order',
              id: item.id,
            },
          }
          : {
            name: 'Order',
            params: {
              id: item.id,
            },
          }),
      }),
    },
    {
      name: 'orders.created_at',
      value: 'createdAt',
      text: 'Date',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Complete',
              value: 'completable',
              prop: 'completable',
              callBack: (item, store) => {
                store.dispatch('comboOrders/confirmedComplete', item.id);
              },
            },
            {
              text: 'Reject',
              value: 'rejectable',
              prop: 'rejectable',
              callBack: (item, store) => {
                store.dispatch('comboOrders/confirmedReject', item.id);
              },
            },
            {
              text: 'Approve',
              value: 'approvable',
              prop: 'approvable',
              callBack: (item, store) => {
                store.dispatch('comboOrders/confirmedApprove', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;
            if (tableElement.actions[prop]) return true;

            return false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
